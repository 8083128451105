<template>
  <section class="news">
    <v-flex xs12 class="ctk-top-banner">
      <v-img
        v-if="info.banners[0]"
        :src="info.banners[0].img"
        class="ctk-top-banner"
        >
        </v-img>
    </v-flex>
    <v-container grid-list-md text-center class="ctk-div" id="ctk_top">
      <template v-for="(item, n) in news.data">
        <v-layout wrap :key="n"  mb-12 pa-2 class="ctk-new-div">
          <template v-if="n % 2 ==0">
            <v-flex xs12 sm4 md4 lg4>
              <v-hover v-slot:default="{ hover }">
                <v-card
                  flat
                  class="mx-auto"
                >
                  <a :href="'/new/' + item.id">
                    <v-img
                      :src="item.attach.thumb"
                      aspect-ratio="1"
                      max-height="300px"
                    ></v-img>
                  </a>
                </v-card>
              </v-hover>
            </v-flex>
            <v-flex xs12 sm8 md8 lg8 pl-10 pr-10 class="text-left" style="position: relative;" :key="n">
              <a :href="'/new/' + item.id">
                <h3 class="ctk-news-title">{{item.title}}</h3>
                <section class="ctk-news-profile">{{item.profile}}</section>
              </a>
              <div class="ctk-news-date">
                <span>{{item.create_time.substring(0, 10)}}</span>
              </div>
            </v-flex>
          </template>
          <template v-else>
            <v-flex xs12 sm8 md8 lg8 pl-10 pr-10 class="text-left" style="position: relative;" :key="n">
              <a :href="'/new/' + item.id">
                <h3 class="ctk-news-title">{{item.title}}</h3>
                <section class="ctk-news-profile">{{item.profile}}</section>
              </a>
              <div class="ctk-news-date">
                <span>{{item.create_time.substring(0, 10)}}</span>
              </div>
            </v-flex>
            <v-flex xs12 sm4 md4 lg4>
              <v-hover v-slot:default="{ hover }">
                <v-card
                  flat
                  class="mx-auto"
                >
                  <a :href="'/new/' + item.id">
                    <v-img
                      :src="item.attach.thumb"
                      aspect-ratio="1"
                      max-height="300px"
                    ></v-img>
                  </a>
                </v-card>
              </v-hover>
            </v-flex>
          </template>
        </v-layout>
      </template>
      <template>
        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="totalPage"
            circle
          ></v-pagination>
        </div>
      </template>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 4,
    introduce: null,
    introduceText: null,
    news: {
      'introduction': null
    },
    totalPage: 1,
    page: null
  }),
  created () {
    document.querySelector('#menu_news').classList.add('v-btn--active')
    this.getPageData()
    this.page = 1
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    },
    params: {
      handler: function (val, oldVal) {
        let type = 2
        let page = val.page
        document.querySelector('#ctk_top').scrollIntoView(true)
        this.getNews(type, page)
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    },
    params () {
      const { page } = this
      return {
        page
      }
    }
  },
  methods: {
    getPageData () {
      this.introduce = this.$t('news.introduce')
      this.detail = this.$t('detail')
      this.publish = this.$t('publish')
    },
    getNews (type = 2, page = 1) {
      this.totalPage = 1
      this.https.get('news', { type: type, page: page }).then(response => {
        if (response.code === 0) {
          this.news = response.data
          this.totalPage = this.news.last_page
          this.page = page
        }
      })
    }
  }
}
</script>
<style>
  .v-application ul, .v-application ol {
    padding-left: 0;
  }
  .ctk-new-div {
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  }
  .ctk-new-div:hover {
    background: #F9FAFD;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  }
</style>
